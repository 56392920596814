import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const Benifits = () => {
  const singleBenifits = [
    {
      id: 1,
      icon: "coin",
      title: "WOB Token",
      text: `WOB token holders will be able to share a percentage of the NFT marketplace fees, participate in the DAO, be eligible for airdrops, get exclusive NFT and many other benefits`,
    },
    {
      id: 2,
      icon: "nft",
      title: "VegaMarket",
      text: `VegaMarket is our NFT MarketPlace. Creator and community oriented, users will be able to participate in the marketplace choices, receive airdrops from the platform fees.`,
    },
    {
      id: 3,
      icon: "game",
      title: "Age of APES",
      text: `Is a P2E about "history of humanity" based on yield farming and NFT. Users will discover and learn the history of the world while farming and collecting NFT/tokens`,
    },
  ];
  return (
    <>
      <section
        id="products"
        className="py-24 dark:bg-jacarta-900 benifit-section"
      >
        <h2 className="mb-6 text-3xl font-medium text-center font-display text-jacarta-700 dark:text-white">
          Our products
        </h2>
        <div className="container">
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            spaceBetween={30}
            slidesPerView="auto"
            breakpoints={{
              240: {
                slidesPerView: 1,
              },
              565: {
                slidesPerView: 1,
              },
              995: {
                slidesPerView: 3,
              },
            }}
            className="!pt-10"
          >
            {singleBenifits.map(item => {
              const { id, icon, title, text } = item;
              return (
                <SwiperSlide className="overflow-visible text-white" key={id}>
                  <div
                    className="mb-12 rounded-2.5xl border border-jacarta-100 bg-white p-8 pt-0 text-center transition-shadow hover:shadow-xl dark:border-jacarta-600 dark:bg-jacarta-700"
                    key={id}
                  >
                    <div className="mb-9 -mt-8 inline-flex h-[5.5rem] w-[5.5rem] items-center justify-center rounded-full border border-jacarta-100 bg-white dark:border-jacarta-600 dark:bg-jacarta-700">
                      <img
                        src={`/images/${icon}.svg`}
                        alt="icon"
                        width="24"
                        height="24"
                        className="w-12 h-12 "
                      />
                    </div>

                    <h3 className="mb-4 text-lg font-display text-jacarta-700 dark:text-white">
                      {title}
                    </h3>
                    <p className="dark:text-jacarta-300 text-jacarta-700">
                      {text}
                    </p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Benifits;
