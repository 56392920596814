import Link from "next/link";

const hero_12 = () => {
  return (
    <section className="relative h-screen">
      <picture className="absolute inset-x-0 top-0 pointer-events-none -z-10 dark:hidden">
        <img
          className="w-full h-full"
          src="/images/gradient.jpg"
          alt="gradient"
        />
      </picture>
      <picture className="absolute inset-x-0 top-0 hidden pointer-events-none -z-10 dark:block">
        <img
          className="w-full h-full"
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
        />
      </picture>

      <picture className="pointer-events-none absolute inset-y-0 right-6 -z-10 hidden items-center justify-end md:flex xl:right-[10%]">
        <div className="w-3/4 text-center xxl:w-auto">
          <div class="relative text-center md:pl-8 md:text-right">
            <svg
              viewBox="0 0 200 200"
              xmlns="http://www.w3.org/2000/svg"
              class="mt-8 inline-block w-72 rotate-[8deg] sm:w-full lg:w-[24rem] xl:w-[35rem]"
            >
              <defs>
                <clipPath id="clipping" clipPathUnits="userSpaceOnUse">
                  <path
                    d="
                    M 0, 100
                    C 0, 17.000000000000004 17.000000000000004, 0 100, 0
                    S 200, 17.000000000000004 200, 100
                        183, 200 100, 200
                        0, 183 0, 100
                "
                    fill="#9446ED"
                  ></path>
                </clipPath>
              </defs>
              <g clip-path="url(#clipping)">
                <image
                  href="images/nft2.webp"
                  width="200"
                  height="200"
                  clip-path="url(#clipping)"
                ></image>
              </g>
            </svg>
            <img
              src="images/hero/3D_elements.png"
              alt=""
              class="absolute top-0 animate-fly md:-right-[5%] md:top-[10%]"
            />
          </div>
        </div>
      </picture>

      <div className="ml-auto mr-auto h-full max-w-[91rem] px-4">
        <div className="grid items-center h-full gap-4 md:grid-cols-12">
          <div className="flex flex-col items-center justify-center h-full col-span-6 py-10 md:items-start lg:py-20">
            <p className="mb-5 inline-block bg-gradient-to-r from-[#F43771] to-[#FF9567] bg-clip-text text-lg text-transparent">
              World Of Bricks
            </p>
            <h1 className="mb-8 text-5xl text-center dark:text-white text-jacarta-700 font-display md:text-left lg:text-6xl">
              Decentralized Marketplace, DAO and games on Conflux Network
            </h1>
            <div className="flex space-x-4">
              <Link href="#howToBuy">
                <a className="px-8 py-3 font-semibold text-center text-white transition-all rounded-full bg-accent shadow-accent-volume hover:bg-accent-dark">
                  Get Started
                </a>
              </Link>
              <Link
                href="https://world-of-bricks.gitbook.io/world-of-bricks"
                target="_blank"
              >
                <a className="px-8 py-3 font-semibold text-center transition-all bg-white rounded-full text-accent shadow-white-volume hover:bg-accent-dark hover:text-white hover:shadow-accent-volume">
                  Documentation
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default hero_12;
