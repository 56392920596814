import React from "react";

const HowToBuyBanner = () => {
  return (
    <section id="howToBuy" className="relative pt-20 mb-12">
      <picture className="absolute inset-0 pointer-events-none -z-10 dark:hidden">
        <img
          src="/images/gradient_light.jpg"
          alt="gradient"
          className="w-full h-full"
        />
      </picture>
      <picture className="absolute inset-0 hidden pointer-events-none -z-10 dark:block">
        <img
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
          className="w-full h-full"
        />
      </picture>
      {/* bg banner */}

      <div className="container">
        <div className="flex flex-col items-center space-y-10 lg:flex-row lg:space-y-0 lg:space-x-4">
          <div className="mb-10 lg:order-1 lg:w-2/4 xl:w-[60%]">
            <div className="text-center lg:text-left">
              <h2 className="mb-6 text-xl font-display text-jacarta-700 dark:text-white lg:pr-4">
                <p className="mb-4"> How to buy $WOB ?</p>
                <p className="mt-4 text-jacarta-300 text-md">
                  - Go to Swappi Dex
                </p>
                <p className="mt-4 text-jacarta-300 text-md">
                  - Select a token
                </p>
                <p className="mt-4 text-jacarta-300 text-md">
                  - Add the address :{" "}
                  <span className="text-sm text-jacarta-700 dark:text-white md:text-md">
                    0x73dA7B062D57e8069b7762d16DaB42F9F75cfa76
                  </span>
                </p>
                <p className="mt-4 text-jacarta-300 text-md">
                  - Swap CFX for WOB
                </p>
              </h2>
              <div className="inline-flex space-x-4">
                <a
                  href="https://app.swappi.io/#/swap"
                  target="_blank"
                  className="flex items-center px-6 py-3 font-semibold text-center transition-all bg-white rounded-full group text-jacarta-500 shadow-white-volume hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                >
                  Buy $WOB
                </a>
              </div>
            </div>
          </div>
          {/* End :lg=prder-1 */}

          <div className="order-3 text-center lg:order-2 lg:w-1/4 lg:self-end xl:w-[40%]">
            <img
              src="/images/swappi.png"
              className="inline-block rounded shadow-lg"
              alt=""
            />
          </div>
          {/* mobile app */}

          {/*scan downaload app */}
        </div>
      </div>
    </section>
  );
};

export default HowToBuyBanner;
