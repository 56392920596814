import Faq from "./Faq";

const index = () => {
  return (
    <section id="wob" className="py-24 bg-light-base dark:bg-jacarta-800">
      <div className="container max-w-xl mx-auto mb-12 text-center">
        <div className="mx-auto mb-12 text-center ">
          <h2 className="mb-6 text-3xl font-medium text-center font-display text-jacarta-700 dark:text-white">
            What's World Of Bricks ?
          </h2>
          <p className="text-lg dark:text-jacarta-300 text-jacarta-800">
            WOB is a project based on the Conflux Network Blockchain
          </p>
        </div>
        <div className="items-center justify-center lg:flex lg:flex-nowrap">
          <div className="lg:w-[100%] mx-auto ">
            <Faq />
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
