import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const RoadMap = () => {
  const [itemsTabs, setItemsTabs] = useState(1);

  const roadMapList = [
    {
      id: 1,
      tabMenu: "2023 Q2",
    },
    {
      id: 2,
      tabMenu: "2023 Q3",
    },
    {
      id: 3,
      tabMenu: "2023 Q4",
    },
    {
      id: 4,
      tabMenu: "2024 Q1",
    },
  ];

  const roadMapContent = [
    {
      id: 1,
      text: ["Social Media / community", "Website release", "Token WOB"],
    },
    {
      id: 2,
      text: [
        "Marketplace V1",
        " Docs",
        "First partnerships",
        "First NFT collection",
        "Community campaign",
        "Age of Apes collection gen0",
        "Age of Apes launch: first age (Prehistoric period)",
      ],
    },
    {
      id: 3,
      text: [
        "Age of Apes collection gen1",
        "Age of Apes: second age",
        "Second community campaign",
        "Age of Apes collection gen2",
        "Age of Apes: Third age",
      ],
    },
    {
      id: 4,
      text: [
        "Marketplace v2",
        "DAO release",
        "Age of Apes: Ancient Period launch",
        "First DeFi product",
      ],
    },
  ];

  return (
    <section
      id="roadmap"
      className="relative py-24 bg-center bg-no-repeat bg-cover after:absolute after:inset-0 after:bg-jacarta-900/60"
      style={{
        backgroundImage: 'url("/images/ico-landing/ico_landing_roadmap.jpg")',
      }}
    >
      <div className="container relative z-10">
        <h2 className="mb-6 text-3xl text-white font-display">Roadmap</h2>
        <p className="max-w-xl mb-12 text-lg text-jacarta-300">
          This timeline details our development goals.
        </p>

        <Tabs>
          <div className="flex">
            <TabList className="self-start w-1/3 py-2 pl-2 border-l-2 nav nav-tabs space-y-9 border-jacarta-200 md:pl-8">
              {roadMapList.map(item => (
                <Tab
                  className="nav-item"
                  key={item.id}
                  onClick={() => setItemsTabs(item.id)}
                >
                  <button
                    className={
                      itemsTabs === item.id
                        ? "active nav-link nav-link--style-3 relative flex items-center whitespace-nowrap text-jacarta-300 hover:text-white"
                        : "nav-link nav-link--style-3 relative flex items-center whitespace-nowrap text-jacarta-300 hover:text-white"
                    }
                  >
                    <span className="px-2 text-lg font-medium nav-link--style-3 font-display md:text-2xl">
                      {item.tabMenu}
                    </span>
                  </button>
                </Tab>
              ))}
            </TabList>
            {/* End Tablist  */}

            <div className="w-full pl-4 tab-content md:w-2/4">
              {roadMapContent.map(item => (
                <TabPanel key={item.id}>
                  <ul>
                    {item.text.map((text, index) => {
                      return (
                        <li
                          key={index}
                          className="mb-3 text-lg text-white nav-link--style-3"
                        >
                          {text}
                        </li>
                      );
                    })}
                  </ul>
                </TabPanel>
              ))}
            </div>
            {/* End tab-content */}
          </div>
        </Tabs>
      </div>
    </section>
  );
};

export default RoadMap;
