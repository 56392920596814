/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-4 w-4 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const itemContent = [
  {
    id: 1,
    title: "Our vision",
    descriptions: `As loyal supporters of Conflux Network and its performance, we want to build our applications world on the Conflux Network. Each of them will be a brick that will move us forward towards the network adoption`,
  },
  {
    id: 2,
    title: "What's the $WOB token ?",
    descriptions: `WOB token holders will be able to share a percentage of the NFT marketplace fees, participate in the DAO, play in Age of Apes and many other benefits.`,
  },
  {
    id: 3,
    title: "Is the project safe ?",
    descriptions: `Yes the project is safe, all the LP and wallets are locked and all contracts are audited. You can find the audits on our documentation.`,
  },
  {
    id: 4,
    title: "When the project launched ?",
    descriptions: `The $WOB token was launched on May 11, 2023. We will launch our NFT MarketPlace during 2023 Q3 and the Age of Apes game during 2023 Q4.`,
  },
  {
    id: 5,
    title: "Who is behind World of Bricks ?",
    descriptions: `Our team have four members: two developers, a graphic designer and a community manager/content Creator. We love Conflux Network and we want to help reinforce and spread the influence of its ecosystem.`,
  },
];

const FaqAccordion = () => {
  const [open, setOpen] = useState(0);

  const handleOpen = value => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <>
      {itemContent.map(item => (
        <Accordion
          className="h-auto mb-5 overflow-hidden border rounded-lg accordion-item border-jacarta-100 dark:border-jacarta-600"
          key={item.id}
          open={open === item.id}
          icon={<Icon id={item.id} open={open} />}
        >
          <AccordionHeader
            className="relative flex items-center justify-between w-full px-4 py-3 text-sm text-left bg-white accordion-button collapsed font-display text-jacarta-700 dark:bg-jacarta-700 dark:text-white"
            onClick={() => handleOpen(item.id)}
          >
            {item.title}
          </AccordionHeader>
          <div className="hidden-style">
            <AccordionBody className="p-4 bg-white border-t accordion-body border-jacarta-100 dark:border-jacarta-600 dark:bg-jacarta-700">
              {item.descriptions}
            </AccordionBody>
          </div>
        </Accordion>
      ))}
    </>
  );
};

export default FaqAccordion;
