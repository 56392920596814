import React, { useEffect } from "react";
import Hero_12 from "../components/hero/hero_12";
import Meta from "../components/Meta";
import RoadMap from "../components/ico-landing/RoadMap";

import Faq from "../components/ico-landing/faq";
import Benifits from "../components/ico-landing/Benifits";
import HowToBuyBanner from "../components/banner/AppBanner";

const Home_12 = () => {
  useEffect(() => {
    const header = document.querySelector("header");
    header.classList.add("bg-white/[.15]");
  }, []);

  return (
    <>
      <Meta title="Home" />
      <Hero_12 />
      <Faq />
      <Benifits />
      <HowToBuyBanner />
      <RoadMap />
    </>
  );
};

export default Home_12;
